@import 'leaflet/dist/leaflet.css';
html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Montserrat', sans-serif;
}

input {
  font-family: 'Montserrat', sans-serif;
}

html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: rgb(30, 30, 30);
}

#root {
   overflow-y: scroll;
  overflow-x: hidden;

}

ul {
  margin: 0;
  padding: 0 !important;
  list-style-type: none;
}

div {
  outline: none !important;
}

a {
  text-decoration: none;
}

button {
  -webkit-tap-highlight-color: transparent;
}

button {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

p {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

#root::-webkit-scrollbar {
  display: none;
}

/* Override default Leaflet popup styles */
.leaflet-popup {
  background: transparent;
  border: none;
  box-shadow: none;
  
}

.leaflet-popup-content-wrapper {
  background: transparent;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
}

.leaflet-popup-tip-container {
  display: none;
}
.leaflet-container a.leaflet-popup-close-button {
  display: none;
}

/* /// added to fix scroll bug /// */
.no-scroll #root{
  overflow: hidden;
 
}
